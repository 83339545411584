<template>
    <div class="kurset-wrapper">
        <table class="table">
            <thead>
                <th scope="col">#</th>
                <th scope="col">Sherbimi</th>
                <th scope="col">Cmimi</th>
                <th scope="col">Kohëzgjatja</th>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">1</th>
                    <td>Perkthimet</td>
                    <td>...</td>
                    <td></td>
                </tr>
                <tr>
                    <th scope="row">2</th>
                    <td>Kurset A1</td>
                    <td>40 € Regjistrimi - Me material | 80 € Muaji</td>
                    <td>5 x 2 orë mësimore gjatë javës</td>
                </tr>
                <tr>
                    <th scope="row">3</th>
                    <td>Super intesiv Kurset A1</td>
                    <td>40 € Regjistrimi - Me material | 120 € Muaji</td>
                    <td>5 x 3 orë mësimore gjatë javës</td>
                </tr>
                <tr>
                    <th scope="row">4</th>
                    <td>Kurset A2</td>
                    <td>40 € Regjistrimi - Me material | 80 € Muaji</td>
                    <td>5 x 2 orë mësimore gjatë javës</td>
                </tr>
                <tr>
                    <th scope="row">5</th>
                    <td>Super intesiv Kurset A2</td>
                    <td>40 € Regjistrimi - Me material | 120 € Muaji</td>
                    <td>5 x 3 orë mësimore gjatë javës</td>
                </tr>
                <tr>
                    <th scope="row">6</th>
                    <td>Kurset B1</td>
                    <td>40 € Regjistrimi - Me material | 120 € Muaji</td>
                    <td>5 x 3 orë mësimore gjatë javës</td>
                </tr>
                <tr>
                    <th scope="row">7</th>
                    <td>Kurset B2</td>
                    <td>40 € Regjistrimi - Me material | 120 € Muaji</td>
                    <td>5 x 3 orë mësimore gjatë javës</td>
                </tr>
                <tr>
                    <th scope="row">8</th>
                    <td>Kurset Konverzacion</td>
                    <td>40 € Muaji</td>
                    <td>Cdo të shtunë nga 4 orë mësimore</td>
                </tr>
                <tr>
                    <th scope="row">9</th>
                    <td>Kurset për Infermierë B1</td>
                    <td>150 € </td>
                    <td>60 orë mësimore, zakonisht mbahen gjatë ditës së shtunë.</td>
                </tr>
                <tr>
                    <th scope="row">10</th>
                    <td>Kurset për Infermierë B2</td>
                    <td>200 € </td>
                    <td>80 orë mësimore, zakonisht mbahen gjatë ditës së shtunë.</td>
                </tr>
                <tr>
                    <th scope="row">11</th>
                    <td>Gjermanisht për profesionin e mjekësisë B1/C1 <br> Parapërgatitje për provimin profesional të gjuhës për mjekët</td>
                    <td>250 € </td>
                    <td>100 orë mësimore, zakonisht mbahen gjatë ditës së shtunë.</td>
                </tr>
                <tr>
                    <th scope="row">12</th>
                    <td>Kurset përgatitore për provimet e niveleve A1, A2, B1 dhe B2</td>
                    <td>150 €</td>
                    <td>32 orë mësimore, zakonisht mbahet gjatë fundjavës.</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
export default {
    name: 'Kurset',
}
</script>

<style lang="scss" scoped>

</style>
